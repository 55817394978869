import React, { useEffect } from 'react';
import { ReactComponent as ContractIcon } from 'images/contract-review-icon.svg';
import { ReactComponent as StudentIcon } from 'images/student-forgivness-icon.svg';
import { ReactComponent as CheckIcon } from 'images/action-items/check.svg';
import Header from 'components/FinancialCheckup/YourFinances/Header';
import Button from 'components/Button';
import LinkButton from 'components/LinkButton';
import ActionItems from 'components/FinancialCheckup/YourFinances/ActionItems/ActionItems';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import {
  getStudentLoanAssistanceData,
  getStudentLoanApplication,
  getApplicationData as getApplicationDataThunk,
} from 'thunks';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { useActionItems } from 'components/FinancialCheckup/YourFinances/ActionItems/useActionItems';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { formatTextDateAndTime } from 'utils/dateUtils';
import { StudentLoanAssistancePaymentStatus } from 'handlers/applicationData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { getStudentLoanApplication as getStudentLoanApplicationData } from 'selectors/getStudentLoanApplication';
import { useApplySteps } from 'components/StudentLoanForgiveness/Apply/applySteps';

import { isWithinAnHour, useFinancialCheckupData } from './yourFinancesUtils';
import Debts from './Debts/Debts';
import ReviewCard from './ReviewCard';

import styles from './YourFinances.module.scss';

const YourFinances = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { id: applicationId, status, createdAt, studentLoanAssistanceAppointment, loanAmount } =
    useSelector(getApplicationData).application ?? {};

  const {
    applicationData: { paymentStatus },
  } = useSelector(getStudentLoanApplicationData);

  const { totalDebt } = useFinancialCheckupData();
  const { actionItems, nextRoute } = useActionItems();
  const initialFlow = createdAt ? isWithinAnHour(new Date(createdAt)) : false;
  const { stepsCompleted } = useApplySteps();

  const getStarted = () => {
    nextRoute && navigate(nextRoute);
  };

  useEffect(() => {
    if (applicationId) {
      Promise.all([
        dispatchWithUnwrap(getApplicationDataThunk(applicationId)),
        dispatchWithUnwrap(getStudentLoanAssistanceData({ applicationId })),
        dispatchWithUnwrap(getStudentLoanApplication({ applicationId })),
      ]);
    }
  }, []);

  const reviewItems = (
    <>
      {(status === ApplicationStatusName.ManualVerify || status === ApplicationStatusName.FinalReview) && (
        <ReviewCard
          icon={<ContractIcon className={styles.reviewIcon} />}
          title="In Review"
          content="Your loan application is in review. We'll notify you when it's time to take action again." // TODO: Add "healthcare employee" in front of "loan"
        />
      )}
      {studentLoanAssistanceAppointment && new Date(studentLoanAssistanceAppointment.date) > new Date() && (
        <ReviewCard
          icon={<ContractIcon className={styles.reviewIcon} />}
          title="Upcoming Student Loan Review"
          content={
            <>
              You're set to talk with a student loan advisor on{' '}
              <strong>{formatTextDateAndTime(new Date(studentLoanAssistanceAppointment.date))}</strong>
            </>
          }
        />
      )}
      {status === ApplicationStatusName.Funded && (
        <ReviewCard
          icon={<CheckIcon className={styles.reviewIcon} />}
          title="Loan Approved!"
          content={
            <>
              Your <strong>{formatMonetaryAmount(loanAmount)}</strong> loan is approved! You should expect to receive
              your funds 3 business days after approval.
            </> // TODO: Add "healthcare employee" in front of "loan"
          }
        />
      )}
      {stepsCompleted &&
        (paymentStatus === StudentLoanAssistancePaymentStatus.Active ||
          paymentStatus === StudentLoanAssistancePaymentStatus.Completed) && (
          <ReviewCard
            icon={<StudentIcon className={styles.reviewIcon} />}
            title="Student Loans Forgiveness Application Completed"
            content={
              <>
                We will contact you with updates on your forgiveness status, and if we need any additional information.{' '}
                <br />
                <LinkButton
                  className={styles.reviewLink}
                  onClick={() => {
                    navigate(RoutePath.StudentLoanApplySteps);
                  }}
                >
                  Edit Your Application
                </LinkButton>
              </>
            }
          />
        )}
    </>
  );

  const { header, content } = initialFlow
    ? {
        header: <Header totalDebt={totalDebt} />,
        content: (
          <>
            <Debts />
            {reviewItems}
            <ActionItems items={actionItems} />
          </>
        ),
      }
    : {
        header: <Header totalDebt={totalDebt} hideTotalDebt />,
        content: (
          <>
            <ActionItems items={actionItems} />
            {reviewItems}
            <Debts showTotalDebt joinCategories />
          </>
        ),
      };

  return (
    <>
      {header}
      <div className={styles.content}>{content}</div>

      <Button className={styles.button} onClick={getStarted}>
        Get Started
      </Button>
    </>
  );
};

export default YourFinances;
